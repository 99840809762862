import { store } from '@/store/store';
import { appointment } from '@/shared/dtos/appointment';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';
import { nutricionistaDto } from "@/shared/dtos/nutricionista-dto";

@Module({
    namespaced: true,
    name: 'appointmentModule',
    store,
    dynamic: true
})
class appointmentModule extends VuexModule {
    public appointments: appointment[] = [];
    public appointment: appointment = new appointment();
    public nutricionistas: nutricionistaDto[] = [];

    @Action({ commit: 'onGetappointments' })
    public async getappointments() {
        return await ssmHttpService.get(API.appointments + '/paciente_citas');
    }

    @Action({ commit: 'onGetappointment' })
    public async getappointment(id: any) {
        return await ssmHttpService.get(API.appointments + '/' + id);
    }

    @Action
    public async guardarappointment(appointment: appointment) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.appointments + '/nueva_cita_app/', appointment.toJson());

    }

    @Action
    public async modificarappointment(appointment: appointment) {
        await ssmHttpService.put(API.appointments + '/actualizar_cita_app/', appointment);
    }

    @Action({ commit: 'onGetNutricionistas' })
    public async getNutricionistas() {
        return await ssmHttpService.get(API.Nutricionista + '/NutricionistasFromPaciente/');
    }

    @Mutation
    public async onGetappointments(res: appointment[]) {
        this.appointments = res;
    }
    @Mutation
    public Addappointment(add: appointment) {
        this.appointments.push(add);
    }
    @Mutation
    public async onGetappointment(res: appointment) {
        this.appointment = new appointment(res);
    }
    @Mutation
    public async onGetNutricionistas(res: nutricionistaDto[]) {
        this.nutricionistas = res;
    }
}
export default getModule(appointmentModule);