import { BaseDto } from './base-dto';

export class appointment extends BaseDto {
    public StartDate!:Date;
    public EndDate!:Date;
    public AllDay !: boolean;
    public Subject !: string;
    public Description !: string;
    public Status !: number;
    public Id_entrevista!:number;
    public Id_nutricionista!:number;
    public CustomField1 !: number;
    public ResourceID!:number;
    public UniqueID!:number;
    public nombre_nutricionista!:string;
    public direccion!:string;
    public fecha!: string;
    public hora!:string;
 } 
