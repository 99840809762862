
































































































































































import AppointmentModule from "@/store/modules/appointment-module";
import { RouterNames } from "@/router/routernames";
import { Component, Vue } from "vue-property-decorator";
import { appointment } from "@/shared/dtos/appointment";
@Component({
  components: {},
})
export default class CitasMenu extends Vue {
  private tab: any = 0;
  private shw_cancel: boolean = false;
  private cancel_cita = new appointment();
  public created() {
    AppointmentModule.getappointments();
    AppointmentModule.getNutricionistas();
  }
  public get citas_pendientes() {
    try {
      return AppointmentModule.appointments.filter((x) => x.ResourceID === 0);
    } catch (error) {
      return [];
    }
  }
  public get citas_realizadas() {
    try {
      return AppointmentModule.appointments.filter((x) => x.ResourceID === 1);
    } catch (error) {
      return [];
    }
  }
  public get citas_canceladas() {
    try {
      return AppointmentModule.appointments.filter((x) => x.ResourceID === 2);
    } catch (error) {
      return [];
    }
  }
  public get TengoNutrisParaCita() {
    return AppointmentModule.nutricionistas.length > 0;
  }
  public get tabs_citas() {
    return [
      { tab: "Pendientes", tipo: 1 },
      { tab: "Realizadas", tipo: 2 },
      { tab: "Canceladas", tipo: 3 },
    ];
  }
  public get stilo() {
    let h = this.$vuetify.breakpoint.height - 150;
    return "max-height:" + h + "px;overflow: auto";
  }
  public Add() {
    this.$router.push({ name: RouterNames.citas_new });
  }
  public CancelarCitaBtn(cita: appointment) {
    this.cancel_cita = cita;
    this.shw_cancel = true;
  }
  public CancelarCita() {
    let index = AppointmentModule.appointments.findIndex(
      (x) => x.UniqueID === this.cancel_cita.UniqueID
    );
    if (index >= 0) {
      let appointment = AppointmentModule.appointments[index];
      appointment.ResourceID = 2;
      AppointmentModule.modificarappointment(appointment).then(() => {
        AppointmentModule.appointments.splice(
          AppointmentModule.appointments.findIndex(
            (x) => x.UniqueID === this.cancel_cita.UniqueID
          ),
          1
        );
      });
    }
    this.shw_cancel = false;
  }

  public EditCitaBtn(cita: appointment) {
    this.$router.push({
      name: RouterNames.citas_edit,
      params: { id_cita: cita.UniqueID.toString() },
    });
  }
}
